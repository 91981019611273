import { useEffect, useState } from "react";
// import Popular from "./popular/page";
import { FaPlay } from "react-icons/fa";
import { url_game } from "./config";

function useMobilePlatform() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobilePlatforms = /android|iphone|ipod|windows phone|blackberry/i;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    setIsMobile(mobilePlatforms.test(userAgent) || isIOS);
  }, []);

  return isMobile;
}

export default function Home() {
  const isMobile = useMobilePlatform();

  const handleFullScreen = () => {
    const iframe = document.getElementById("game-iframe");
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  };

  const handleClick = () => {
    window.location.href = "#/game";
  };

  return (
    <div className="relative z-50 bg-[#ECF7FF]">
      {!isMobile ?
        <div className="2xl:flex lg:flex flex items-center justify-center 2xl:h-[85vh] lg:h-[75vh] h-[65vh] lg:mx-24 mx-8">
          <iframe
            id="game-iframe"
            src={url_game}
            width="100%"
            height="100%"
            frameBorder="0"
            title="Snake Game"
            style={{
              aspectRatio: "16 / 9",
              objectFit: "cover",
              borderRadius: "8px",
              maxWidth: "fit-content",
              border: "2px solid #3D2A7D",
            }}
          ></iframe>
        </div>
        :
        <div className="flex flex-col items-center justify-center h-[35vh] lg:mx-24 mx-8 opacity-90 rounded-lg">
          <div className="relative w-full h-full flex flex-col items-center justify-center">
            <div className="absolute inset-0 bg-playnow opacity-50"></div>
            <button className="w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center relative animate-zoom" onClick={handleClick}>
              <FaPlay className="text-black w-6 h-6" />
            </button>
            <a className="poppins-bold font-bold text-[20px] text-white mt-3 relative"
              href="#/game"
              style={{ textShadow: "1px 1px 2px black, -1px -1px 2px black, 1px -1px 2px black, -1px 1px 2px black" }}
            >Play now</a>
          </div>

        </div>
      }
      <div className="flex items-center justify-center">
        <div className="container">
          {!isMobile && <div className="2xl:flex lg:flex md:hidden hidden justify-center mt-6 2xl:mb-[40px] lg:mb-[28px] mb-[28px]">
            <button
              onClick={handleFullScreen}
              className="btn-full-screen 2xl:py-6 2xl:px-16 lg:py-3 lg:px-8 md:py-3 md:px-8 px-4 py-2 rounded"
            >
              <span className="lilita-one-regular 2xl:text-4xl lg:text-3xl md:text-3xl text-white">
                Full Screen
              </span>
            </button>
          </div>}
          {/* <Popular /> */}
          <div className='w-full 2xl:pt-1 lg:pt-1 pt-10 bg-[#F8FFFF]'>
            <div className='2xl:mx-56 2xl:mt-14 2xl:pb-14 md:pb-14 md:mt-14 mt-10 lg:mx-20 md:mx-9 mx-6 pb-10'>
              <h1 className='2xl:text-[40px] md:text-[40px] text-[32px] lilita-one-regular text-[#0462E9]'>Subway Surfers: The Ultimate Endless Runner Game</h1>
              <div className='2xl:mt-6 md:mt-6'>
                <div className='text-base flex !text-justify md:text-lg 2xl:text-lg lg:text-lg poppins-regular text-[#383838]'>
                  Subway Surfers is one of the most popular mobile games in the endless runner genre, developed by Kiloo and SYBO Games. Since its release in 2012, the game has captured the hearts of millions of players worldwide with its vibrant graphics, engaging gameplay, and constant updates that keep the experience fresh.
                </div>
              </div>
            </div>
          </div>
          <div className="text-[#383838] lg:px-20 md:px-9 px-6 2xl:pt-20 lg:pt-20 md:pt-14 content-des 2xl:px-56 2xl:pb-20 md:pb-14 pt-14 pb-14">
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              What Makes Subway Surfers So Addictive?
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              The core of Subway Surfers lies in its simplicity and thrill. Players take on the role of a mischievous teenager, running endlessly through subway tracks to escape a grumpy inspector and his dog. The mission is straightforward:
              <ul style={{
                listStyleType: 'disc',
                marginLeft: '30px',
                marginTop: '10px',
                marginBottom: '10px'
              }}>
                <li>
                  Dodge obstacles like trains, barriers, and tunnels.
                </li>
                <li>
                  Collect coins and special items to boost your score.
                </li>
                <li>
                  Use power-ups like jetpacks, hoverboards, or magnets to gain an advantage.
                </li>
                <li>
                  Unlock characters and boards to customize your experience.
                </li>
              </ul>
              The game constantly challenges players as the speed increases, demanding quick reflexes and strategic moves. With no "end" to the game, players are motivated to beat their own or their friends' high scores.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Stunning Graphics and Dynamic Sound Effects
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              One of the standout features of Subway Surfers is its eye-catching graphics. The 3D visuals are bright, colorful, and continuously updated to reflect global themes. From the bustling streets of Tokyo to the serene beaches of Rio, the game takes players on a world tour with its regular "World Tour Updates."
            </div>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Accompanying the stunning visuals is a dynamic soundtrack that keeps the adrenaline pumping. The sound effects, from the clinking of coins to the honking of trains, immerse players in the fast-paced action.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Key Features of Subway Surfers
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              <ul style={{
                marginLeft: '30px',
                marginTop: '10px'
              }} className="numbered-list">
                <li>
                  Simple Yet Engaging Gameplay: Swipe left, right, up, or down to control your character. Easy to learn but hard to master.
                </li>
                <li>
                  Frequent Updates: With new cities, characters, and boards introduced every few weeks, the game stays fresh and exciting.
                </li>
                <li>
                  Competitive Leaderboards: Subway Surfers brings out the competitor in you. Challenge your friends or climb the global rankings to prove you're the ultimate Subway Runner.
                </li>
                <li>
                  Customizable Characters and Hoverboards: Unlock a wide range of characters and hoverboards to personalize your gameplay.
                </li>
                <li>
                  Offline Play: Enjoy the game anytime, anywhere, even without an internet connection.
                </li>
              </ul>
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Why Is Subway Surfers Still Relevant Today?
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Over a decade since its release, Subway Surfers continues to dominate mobile gaming charts. Its success can be attributed to its:
              <ul style={{
                listStyleType: 'disc',
                marginLeft: '30px',
                marginTop: '10px'
              }}>
                <li>
                  Universal Appeal: The game is suitable for players of all ages.
                </li>
                <li>
                  Constant Evolution: The developers consistently release updates with new content, making the game feel fresh.
                </li>
                <li>
                  Nostalgic Value: For many players, Subway Surfers is a classic that evokes fond memories of early mobile gaming.
                </li>
              </ul>
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Tips and Tricks to Master Subway Surfers
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              <ul style={{
                listStyleType: 'disc',
                marginLeft: '30px',
                marginBottom: '10px'
              }}>
                <li>
                  Prioritize Upgrades: Use coins to upgrade power-ups like the Coin Magnet or Jetpack for longer-lasting boosts.
                </li>
                <li>
                  Focus on Missions: Completing missions earns you additional multipliers, significantly increasing your score.
                </li>
                <li>
                  Use Hoverboards Wisely: Hoverboards act as a shield, saving you from crashing. Activate them in challenging sections to survive longer.
                </li>
                <li>
                  Stay in the Center Lane: It’s easier to switch between lanes and avoid obstacles when you’re in the middle.
                </li>
              </ul>
              Start your journey as a Subway Runner today. Run, dodge, collect, and conquer the tracks – the subway is yours to master!
            </div>

            <div className="control flex items-center 2xl:mt-10 lg:mt-10 mt-4 md:mt-10">
              <div className="2xl:pl-8 2xl:py-8 lg:pl-8 lg:py-8 px-8 py-8">
                <h2 className="2xl:text-2xl lg:text-2xl md:text-2xl text-2xl font-bold text-start lilita-one-regular">
                  How to play Subway Surfers online?
                </h2>
                <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                  You play this game on Game by using your keyboard:
                </div>
                <ul
                  style={{
                    listStyleType: "disc",
                    marginLeft: "30px",
                  }}
                >
                  <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start mt-4 poppins-regular">
                    Left/right arrow - Move left/right
                  </li>
                  <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start poppins-regular">
                    Up arrow - Jump
                  </li>
                  <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start poppins-regular">
                    Down arrow - Roll
                  </li>
                  <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start poppins-regular">
                    Space - Activate hoverboard
                  </li>
                </ul>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}
